<template>
  <div class="home">
    <Viewheader  :isHome="true"/>
    <div class="home-top">
        <div class="home-top-bg"></div>
        <div class="home-top-left">
            <div class="home-top-logo">
                <span>跨境启航</span>
                <div class="logo"><img src="../../assets/img/logo-big1.png" class="ostylehome"></div>
            </div>
            <div class="home-top-title">
                AI助力跨境生意增长
            </div>
            <div class="home-top-content">
                <div class="home-top-item">智能商详</div>
                <div class="home-top-item">智能客服</div>
                <div class="home-top-item">智能导购</div>
                <div class="home-top-item">文案助手</div>
                <div class="home-top-item">AI设计师</div>
                <div class="home-top-item">视频理解</div>
                <div class="home-top-item">视频自动生成等</div>
                <div class="home-top-item">40多种应用，覆盖站外引流、站内营销、店铺安全、直播带货等场景</div>
            </div>
            <div class="home-top-bottom">
                <!-- <router-link to="/aboutUs"><span class="blue">联系我们</span></router-link> -->
                <!-- <span class="">联系我们</span> -->
            </div>
        </div>
        <div class="home-top-right"></div>
    </div>
    <div class="home-wrapper-one">
        <div class="wrapper-one-bg"></div>
        <div class="wrapper-one-right">
            <div class="wrapper-one-title">
                高效引流吸客
            </div>
            <div class="wrapper-one-title2">
                专注海外跨境社媒营销
            </div>
            <div class="wrapper-one-text">
                提供SNS社媒引流解决⽅案和GoogleShopping⼴告引流解决方案。<br />
                社媒引流针对Facebook、Instagram、Tiktok等平台，提供从投放选品、投放素材收集、投放图⽂/视频⽣成、落地承接优化、数据反馈投放选品等全流程解决⽅案。<br />
                GoogleShopping⼴告引流，提高商品⼊池准⼊率，优化投放图⽂素材，提升⾕歌搜索命中，通过优化⻓尾词的图⽂相关性，提升流量精准性及引流转化。
            </div>
        </div>
    </div>
    <div class="home-wrapper-two">
        <div class="wrapper-two-bg"></div>
        <div class="wrapper-two-right">
            <div class="wrapper-two-title">
                店铺安全保驾护航
            </div>
            <div class="wrapper-two-title2">
                专注跨境内容风控审核
            </div>
            <div class="wrapper-two-text">
                提供完整的商品侵权违规识别⽅案。<br />
                包含⽂本、图像及视频常⻅跨境易侵权品牌识别、涉黄识别、涉政识别、涉恐识别、异常商品识别（补邮费、VIP链接等）等能⼒。<br />
                基于海量数据的持续迭代，以更科学的方式降低成本，增加效能。
            </div>
            <div class="wrapper-two-select">
                <div class="wrapper-two-item"><i></i>文本侵权检测</div>
                <div class="wrapper-two-item"><i></i>色情商品识别</div>
                <div class="wrapper-two-item"><i></i>通用品牌logo识别</div>
                <div class="wrapper-two-item"><i></i>商品高危品牌识别</div>
                <div class="wrapper-two-item"><i></i>图像搜索</div>
                <div class="wrapper-two-item"><i></i>文本语义相似</div>
            </div>
        </div>
    </div>
    <Footering />
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue'
import Footering from "../../components/foot/viewfooter.vue";
import "../../assets/css/public.css";
export default {
    name: "newHome",
    components: {
        Viewheader,
        Footering
    },
    mounted(){
    },
    beforeRouteEnter (to, from, next){
        if(from.path === '/logIn' || from.path === '/wechat' || from.path === '/'){
            //来自登录页可读取路由信息
            sessionStorage.setItem('shouldGo','true')
        }
        // sessionStorage.setItem('shouldGo','true')
        next()
    },
}
</script>

<style>
.home{
    /* font-family: PingFang-SC-Medium, PingFang-SC; */
    /* position: relative; */
    width: 100%;
    height: 100%;
    background: #fff;
    color: #333;
    /* margin-top: 60px; */
}
.home-top{
    position: relative;
    width: 100%;
    height: 650px;
}
.home-top-bg{
    position: absolute;
    top: -60px;
    height: 568px;
    width: 100%;
    background: url(../../assets/img/home-top.png) no-repeat center;
    z-index: 1;
    background-size: 100% 100%;
    /* background-size: cover; */
}
.home-top-left{
    position: relative;
    z-index: 2;
    width: 460px;
    padding-top: 24px;
    margin-left: calc(50% - 500px);
}
.home-top-logo span{
    /* float: right; */
    display: block;
    text-align: right;
    font-size: 22px;
    font-family: PingFang-SC-Medium;
}
.home-top-logo .logo{
    font-size: 68px;
    font-weight:bold;
    color: #000;
}
.ostylehome{
    /* width: 52px; */
    padding-top: 2px;
}
.home-top-title{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: PingFang-SC-Bold;
}
.home-top-content{
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: PingFang-SC-Medium;
    /* margin-top:30px */
}
.home-top-bottom span{
    display: inline-block;
    margin-right: 30px;
    box-sizing: border-box;
    width: 180px;
    height: 60px;
    border: 1px solid #6555cf;
    color: #6555cf;
    border-radius: 6px;
    line-height: 60px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
}
.home-top-bottom .blue{
    background: #6555cf;
    color: #fff;
}
.home-top-right{
    position: absolute;
    z-index: 2;
    left: 60%;
    top: 0;
    width: 471px;
    height: 655px;
    background: url(../../assets/img/home-person.png) no-repeat center;
}
.home-wrapper-one{
    width: 1100px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 100px;
}
.home-wrapper-one .wrapper-one-bg{
    width: 754px;
    height: 699px;
    background: url(../../assets/img/home-one.png) no-repeat center;
    background-size: contain;
}
.home-wrapper-one .wrapper-one-right {
    position: absolute;
    top: 135px;
    left: 760px;

}
.home-wrapper-one .wrapper-one-title{
    color: #000;
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 40px;
    font-family:PingFang-SC-Bold, PingFang-SC;
}
.home-wrapper-one .wrapper-one-title2{
    color: #000;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
    font-family:PingFang-SC-Medium;
}
.home-wrapper-one .wrapper-one-text{
    width: 421px;
    font-size: 16px;
    color: #333;
    line-height: 28px;
    font-family:PingFang-SC-Medium;
}
.home-wrapper-two{
    width: 1100px;
    margin: 0 auto;
    position: relative;
    /* margin-bottom: 100px; */
}
.home-wrapper-two .wrapper-two-bg{
    margin-left: 460px;
    width: 741px;
    height: 838px;
    background: url(../../assets/img/home-two.png) no-repeat center;
    background-size: contain;
}
.home-wrapper-two .wrapper-two-right {
    position: absolute;
    top: 135px;
    left: 50px;
    width: 410px;

}
.home-wrapper-two .wrapper-two-title{
    color: #000;
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 40px;
    font-family: PingFang-SC-Bold;
}
.home-wrapper-two .wrapper-two-title2{
    color: #000;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
    font-family: PingFang-SC-Medium;
}
.home-wrapper-two .wrapper-two-text{
    width: 421px;
    font-size: 16px;
    color: #333;
    line-height: 28px;
    font-family: PingFang-SC-Medium;
}
.home-wrapper-two .wrapper-two-select{
    font-size: 18px;
    line-height: 18px;
    font-weight: 500px;
}
.home-wrapper-two .wrapper-two-item{
    margin-top: 25px;
    font-family: PingFang-SC-Medium;
}
.home-wrapper-two .wrapper-two-item i{
    display: inline-block;
    width: 15px;
    height: 12px;
    margin-right: 10px;
    background: url(../../assets/img/checked-icon.png);
}
@media (max-width: 990px)  {
    .home-top{
        height: 400px;
    }
    .home-top-bg{
        right: 0;
        width: 50%;
        height:265px;
        background: linear-gradient(
            -90deg,
            #9c9ef0 0%,
            #9491ed 100%
        );
        border-radius: 0 0 0 80px;
    }
    .home-top-right{
        left: unset;
        right: 10px;
        width: 170px;
        height: 237px;
        background-size: contain;
    }
    .home-top-left{
        margin-left: 0;
        width: auto;
    }
    .home-top-logo .logo{
        display: none;
        margin-left: 20px;
        width: 210px;
    }
    .home-top-logo span{
        display: none;
        margin-left: 180px;
        text-align: left;
    }
    .home-top-title{
        margin-left: 20px;
        font-size: 22px;
        margin-top: 0px;
    }
    .home-top-content{
        margin-left: 20px;
        font-size: 12px;
        margin-bottom: 80px;
    }
    .home-top-bottom{
        width: 280px;
        margin: 0 auto;
        /* margin-left: 20px; */
    }
    .home-top-bottom span{
        width: 120px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        margin-right: 0;
    }
    .home-top-bottom .blue{
        margin-right: 40px;
    }
    .home-wrapper-one{
        width: calc(100% - 40px);
        margin: 0 auto;
        margin-bottom: 50px;
    }
    .home-wrapper-one .wrapper-one-bg{
        width: 100%;
        height: 320px;
    }
    .home-wrapper-one .wrapper-one-right{
        position: static;
    }
    .home-wrapper-one .wrapper-one-title{
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
    }
    .home-wrapper-one .wrapper-one-title2{
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px;
    }
    .home-wrapper-one .wrapper-one-text{
        width: auto;
        font-size: 12px;
    }
    .home-wrapper-two{
        width: calc(100% - 40px);
        margin: 0 auto;
        margin-bottom: 50px;
    }
    .home-wrapper-two .wrapper-two-bg{
        margin-left: 0;
        width: 100%;
        height: 400px;
    }
    .home-wrapper-two .wrapper-two-right{
        position: static;
        width: 100%;
    }
     .home-wrapper-two .wrapper-two-title{
        font-size: 22px;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .home-wrapper-two .wrapper-two-title2{
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px;
    }
    .home-wrapper-two .wrapper-two-text{
        width: auto;
        font-size: 12px;
    }
    .home-wrapper-two .wrapper-two-select{
        font-size: 14px;
    }
    .home-wrapper-two .wrapper-two-item{
        display: inline-block;
        margin-right: 15px;
        min-width: calc(50% - 15px);
    }
    .home-wrapper-two .wrapper-two-item i{
        display: inline-block;
        width: 20px;
        height: 16px;
        margin-right: 10px;
        background: url(../../assets/img/checked-icon.png);
        background-size: 100% 100%;
    }

}
</style>